import React, { useEffect, useState } from 'react'
import './Card.css'
import { WinnerIcon, ContributeIcon, UnStakeIcon, StakeIcon } from '../icons/Icons';
import questionMark from "../../assets/images/question.png";
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { useAccount } from 'wagmi';

function ActivityCard(props) {
    const {poolColor, balances} = props;
    const [activityArray, setActivityArray] = useState([]);
    const [pastWinners, setPastWinners] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(0);
    const { connector, address, isConnected } = useAccount();
const APIURL = 'https://api.thegraph.com/subgraphs/name/clrhc/poolpartygraph';
const poolAddress = "0xfDEB03709453225D22E5a51A7f93bd0E97984D8C";

useEffect(() => {
async function init(){

if(isConnected){
const tokensQuery = `{rebase: rebases(first: 50){id pool1IsWinner amount blockNumber blockTimestamp} stake: transfers(where: {from: "`+address+`", to: "`+poolAddress+`"}){id from to amount blockNumber blockTimestamp} unstake: transfers(where: {to: "`+address+`", from: "`+poolAddress+`"}){id from to amount blockNumber blockTimestamp} contribute: transfers(where: {from: "`+address+`" to: "0x7eAf8FC3eF869381Fb07aD7191bf31805F76CE72"}){id from to amount blockNumber blockTimestamp}}`;
const pastQuery = `{rebase: rebases (first: 100){id pool1IsWinner amount blockNumber blockTimestamp}}`;
const client = new ApolloClient({
  uri: APIURL,
  cache: new InMemoryCache(),
});
let activity = [];
let past = [];
let rebases = [];
let activityQuery = await client.query({
    query:  
        gql(tokensQuery), variables: {first: 50, orderBy: 'blockNumber', orderDirection: 'desc'},
    });

for(let i = 0; i < activityQuery.data.stake.length; i++){
    activity.push([activityQuery.data.stake[i].blockNumber, 'stake',activityQuery.data.stake[i].amount, activityQuery.data.stake[i].blockTimestamp]);
}
for(let i = 0; i < activityQuery.data.unstake.length; i++){
    activity.push([activityQuery.data.unstake[i].blockNumber, 'unstake',activityQuery.data.unstake[i].amount, activityQuery.data.unstake[i].blockTimestamp]);
}
for(let i = 0; i < activityQuery.data.rebase.length; i++){
    activity.push([activityQuery.data.rebase[i].blockNumber, 'rebase',activityQuery.data.rebase[i].amount, activityQuery.data.rebase[i].blockTimestamp, activityQuery.data.rebase[i].pool1IsWinner]);
}
for(let i = 0; i < activityQuery.data.contribute.length; i++){
    activity.push([activityQuery.data.contribute[i].blockNumber, 'contribute', activityQuery.data.contribute[i].amount, activityQuery.data.contribute[i].blockTimestamp])
}

activity.sort();



let winnersQuery = await client.query({
    query:  
        gql(pastQuery), variables: {first: 100, orderBy: 'blockNumber', orderDirection: 'desc'},
    });

for(let i = 0; i < winnersQuery.data.rebase.length; i++){
     past.push([winnersQuery.data.rebase[i].blockNumber, 'rebase',winnersQuery.data.rebase[i].amount, winnersQuery.data.rebase[i].blockTimestamp, winnersQuery.data.rebase[i].pool1IsWinner]);
}

past.sort();
let pastCount = 1;
for(let i = 0; i < past.length; i++){
    if(past[i][1] == "rebase"){
        past[i].push(pastCount);
        pastCount++;
    }
}

activity.reverse()

for(let i = 0; i < activity.length; i++){
    if(activity[i][1] == "rebase"){
        activity[i].push(pastCount-1);
        pastCount--;
    }
}

setActivityArray(activity);
setPastWinners(past.reverse());


}else{
const tokensQuery = `{rebase: rebases (first: 50){id pool1IsWinner amount blockNumber blockTimestamp}}`;
const pastQuery = `{rebase: rebases (first: 100){id pool1IsWinner amount blockNumber blockTimestamp}}`;
const client = new ApolloClient({
  uri: APIURL,
  cache: new InMemoryCache(),
})

let activity = [];
let past = [];
let activityQuery = await client.query({
    query: gql(tokensQuery), variables: {first: 100, orderBy: 'createdAtTimestamp', orderDirection: 'desc',},});
let winnersQuery = await client.query({
    query:  
        gql(pastQuery), variables: {first: 100, orderBy: 'blockNumber', orderDirection: 'desc'},
    });


for(let i = 0; i < winnersQuery.data.rebase.length; i++){
     past.push([winnersQuery.data.rebase[i].blockNumber, 'rebase',winnersQuery.data.rebase[i].amount, winnersQuery.data.rebase[i].blockTimestamp, winnersQuery.data.rebase[i].pool1IsWinner]);
}

for(let i = 0; i < activityQuery.data.rebase.length; i++){
    activity.push([activityQuery.data.rebase[i].blockNumber, 'rebase',activityQuery.data.rebase[i].amount, activityQuery.data.rebase[i].blockTimestamp, activityQuery.data.rebase[i].pool1IsWinner]);
}

activity.sort();

past.sort();
let pastCount = 1;
for(let i = 0; i < past.length; i++){
    if(past[i][1] == "rebase"){
        past[i].push(pastCount);
        pastCount++;
    }
}

activity.reverse()

for(let i = 0; i < activity.length; i++){
    if(activity[i][1] == "rebase"){
        activity[i].push(pastCount-1);
        pastCount--;
    }
}

setActivityArray(activity);
setPastWinners(past.reverse());

}} init();
 const interval = setInterval(() => init(), 15000)
   return () => {
    clearInterval(interval);
   }}, []);

    const showTooltip = () => {
    document.getElementById('toolTipActivity').style.visibility = "visible";
  }

  const hideTooltip = () => {
    document.getElementById('toolTipActivity').style.visibility = "hidden";
  }

    return (
        <>
        <div className="Card activityCard" style={{ '--border-color': poolColor }}>
            <div className='Card-title'>
               <h1 className='Card-title-text'>Activity</h1><img className="questionIcon" width="25" id="activityIcon" onMouseEnter={showTooltip} onMouseLeave={hideTooltip} src={questionMark} />
               <div className="toolTip" id="toolTipActivity"><h3>Activity</h3><p>Keep Track of Wins, Stakes and Other Transactions.</p></div>
            </div>
                        <div className="parent-container-card">
        <div>
          <p
            onClick={() => setSelectedGroup(0)}
            className="child activityText"
            id={selectedGroup === 0 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Real-Time
          </p>
        </div>
        <div>
          <p
            onClick={() => setSelectedGroup(1)}
            className="child activityText"
            id={selectedGroup === 1 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Past Winners
          </p>
        </div>
      </div>
            <div className='activity-container'>
            {selectedGroup === 0 ? <>     
            {activityArray?.map((item, index) => {
                    const bgColor = (() => {
                        switch(item[4]){
                        case true:
                            return "linear-gradient(180deg, #0AB67D 0%, #0AB67D 1%, #0AA589)";
                        case false:
                            return "linear-gradient(180deg, #69B3F7 0%, #69B3F7 1%, #3F6B94)";
                        default:
                            return "#43447E";
                        }
                    })();

                    let now = new Date().getTime();
                    let activityTime = item[3] * 1000;
                    let secondsElapsed = (now - activityTime) / 1000;
                    let elapsedTime;
                    
                    if (secondsElapsed < 60) {
                        elapsedTime = `${Math.floor(secondsElapsed)} seconds ago`;
                    } else if (secondsElapsed < 3600) {
                        elapsedTime = `${Math.floor(secondsElapsed / 60)} minutes ago`;
                    } else if (secondsElapsed < 86400) {
                        elapsedTime = `${Math.floor(secondsElapsed / 3600)} hours ago`;
                    } else if (secondsElapsed < 604800) {
                        elapsedTime = `${Math.floor(secondsElapsed / 86400)} days ago`;
                    } else if (secondsElapsed < 2592000) {
                        elapsedTime = `${Math.floor(secondsElapsed / 604800)} weeks ago`;
                    } else if (secondsElapsed < 31536000) {
                        elapsedTime = `${Math.floor(secondsElapsed / 2592000)} months ago`;
                    } else {
                        elapsedTime = `${Math.floor(secondsElapsed / 31536000)} years ago`;
                    }


                    return (
                        <>
                        <div className='activity-card' key={index} style={{background: bgColor}}>
                                {/* Left */}
                                <div className='item-left'>
                                    <p className='activity-card-title-text'>
                                    {isConnected? <>
                                        {item[1] === "unstake" && (address.slice(0,4)+"..."+address.slice(-4))}
                                        {item[1] === "stake" && (address.slice(0,4)+"..."+address.slice(-4))}
                                        {item[1] === "contribute" && (address.slice(0,4)+"..."+address.slice(-4))}
                                        {item[1] === "rebase" && (item[4] === true && ('Pool 1'))}
                                        {item[1] === "rebase" && (item[4] === false && ('Pool 2'))}
                                     </> : 
                                        <>
                                        {item[1] === "rebase" && (item[4] === true && ('Pool 1'))}
                                        {item[1] === "rebase" && (item[4] === false && ('Pool 2'))}
                                        </>
                                    }
                                    </p>
                                    <p className='activity-card-sub-text'>
                                        {elapsedTime}
                                    </p>
                                </div>

                                {/* Center */}
                                <div className='item-center'>
                                    {item[1] === "unstake" && (<UnStakeIcon />)}
                                    {item[1] === "stake" && (<StakeIcon />)}
                                    {item[1] ==="rebase" && (<WinnerIcon />)}
                                    {item[1] === "contribute" && (<ContributeIcon />)}
                                    <div className='center-card-container'>
                                        <p className='activity-card-title-text'>
                                        {item[1] === "unstake" && ('Unstake')}
                                        {item[1] === "stake" && ('Stake')}
                                        {item[1] === "rebase" && (item[4] === true && ('Winner'))}
                                        {item[1] === "rebase" && (item[4] === false && ('Winner'))}
                                        {item[1] === "contribute" && ('Purchase')}
                                        </p>
                                        <p className='activity-card-sub-text'>
                                            {item[1] === "rebase" && ('Round '+item[5])}
                                            {item[1] === "stake" && ('Party')}
                                            {item[1] === "unstake" && ('Party')}
                                            {item[1] === "contribute" && ('Favor')}
                                        </p>
                                    </div>
                                </div>

                                {/* Right */}
                                <div className='item-right'>
                                    <p className='activity-card-title-text'>
                                        {item [1] === "rebase" && (parseInt(item[2] / Math.pow(10,18)))+" PARTY"}
                                        {item [1] === "stake" && (parseInt(item[2] / Math.pow(10,18)))+" PARTY"}
                                        {item [1] === "unstake" && (parseInt(item[2] / Math.pow(10,18)))+" PARTY"}
                                        {item [1] === "contribute" && "-"+(parseInt(item[2] / Math.pow(10,18)))+" PARTY"}
                                    </p>
                                </div>
                        </div>
                        </>
                    );
                })}</>:
                <>
                {pastWinners?.map((item, index) => {
                    const bgColor = (() => {
                        switch(item[4]){
                        case true:
                            return "linear-gradient(180deg, #0AB67D 0%, #0AB67D 1%, #0AA589)";
                        case false:
                            return "linear-gradient(180deg, #69B3F7 0%, #69B3F7 1%, #3F6B94)";
                        default:
                            return "#43447E";
                        }
                    })();

                    let now = new Date().getTime();
                    let activityTime = item[3] * 1000;
                    let secondsElapsed = (now - activityTime) / 1000;
                    let elapsedTime;
                    
                    if (secondsElapsed < 60) {
                        elapsedTime = `${Math.floor(secondsElapsed)} seconds ago`;
                    } else if (secondsElapsed < 3600) {
                        elapsedTime = `${Math.floor(secondsElapsed / 60)} minutes ago`;
                    } else if (secondsElapsed < 86400) {
                        elapsedTime = `${Math.floor(secondsElapsed / 3600)} hours ago`;
                    } else if (secondsElapsed < 604800) {
                        elapsedTime = `${Math.floor(secondsElapsed / 86400)} days ago`;
                    } else if (secondsElapsed < 2592000) {
                        elapsedTime = `${Math.floor(secondsElapsed / 604800)} weeks ago`;
                    } else if (secondsElapsed < 31536000) {
                        elapsedTime = `${Math.floor(secondsElapsed / 2592000)} months ago`;
                    } else {
                        elapsedTime = `${Math.floor(secondsElapsed / 31536000)} years ago`;
                    }


                    return (
                        <>
                        <div className='activity-card' key={index} style={{background: bgColor}}>
                                {/* Left */}
                                <div className='item-left'>
                                    <p className='activity-card-title-text'>
                                    {isConnected? <>
                                        {item[1] === "unstake" && (address.slice(0,4)+"..."+address.slice(-4))}
                                        {item[1] === "stake" && (address.slice(0,4)+"..."+address.slice(-4))}
                                        {item[1] === "contribute" && (address.slice(0,4)+"..."+address.slice(-4))}
                                        {item[1] === "rebase" && (item[4] === true && ('Pool 1'))}
                                        {item[1] === "rebase" && (item[4] === false && ('Pool 2'))}
                                     </> : 
                                        <>
                                        {item[1] === "rebase" && (item[4] === true && ('Pool 1'))}
                                        {item[1] === "rebase" && (item[4] === false && ('Pool 2'))}
                                        </>
                                    }
                                    </p>
                                    <p className='activity-card-sub-text'>
                                        {elapsedTime}
                                    </p>
                                </div>

                                {/* Center */}
                                <div className='item-center'>
                                    {item[1] === "unstake" && (<UnStakeIcon />)}
                                    {item[1] === "stake" && (<StakeIcon />)}
                                    {item[1] ==="rebase" && (<WinnerIcon />)}
                                    {item[1] === "contribute" && (<ContributeIcon />)}
                                    <div className='center-card-container'>
                                        <p className='activity-card-title-text'>
                                        {item[1] === "unstake" && ('Unstake')}
                                        {item[1] === "stake" && ('Stake')}
                                        {item[1] === "rebase" && (item[4] === true && ('Winner'))}
                                        {item[1] === "rebase" && (item[4] === false && ('Winner'))}
                                        {item[1] === "contribute" && ('Purchase')}
                                        </p>
                                        <p className='activity-card-sub-text'>
                                            {item[1] === "rebase" && ('Round '+item[5])}
                                            {item[1] === "stake" && ('Party')}
                                            {item[1] === "unstake" && ('Party')}
                                            {item[1] === "contribute" && ('Favor')}
                                        </p>
                                    </div>
                                </div>

                                {/* Right */}
                                <div className='item-right'>
                                    <p className='activity-card-title-text'>
                                        {item [1] === "rebase" && (parseInt(item[2] / Math.pow(10,18)))+" PARTY"}
                                        {item [1] === "stake" && (parseInt(item[2] / Math.pow(10,18)))+" PARTY"}
                                        {item [1] === "unstake" && (parseInt(item[2] / Math.pow(10,18)))+" PARTY"}
                                        {item [1] === "contribute" && "-"+(parseInt(item[2] / Math.pow(10,18)))+" PARTY"}
                                    </p>
                                </div>
                        </div>
                        </>
                    );
                })}
                </>}
           
            </div>
        </div>
        
        </>
    )
}

export default ActivityCard