import React, { useState, useEffect } from 'react';
import { useFormik, Formik, Form, Field, ErrorMessage } from "formik";
import { useAccount, isConnected } from 'wagmi';
import { stakePool3, unstakePool3, claimPool3 } from '../../web3/web3';
import * as Yup from 'yup';
import './Card.css';
import { useTxnPending } from '../../contexts/TxnPendingContext';
import { usePoolsClosed } from '../../contexts/PoolsClosedContext';
import { FaPlus, FaMinus, FaLock } from 'react-icons/fa';
import CountdownTimer from '../CountdownTimer/CountdownTimer';
import questionMark from "../../assets/images/question.png";

function Card(props) {
  const [pool1isSmaller, setPool1isSmaller] = useState();
  const { poolColor, balances, poolData, poolId } = props;
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [unstakingFee, setUnstakingFee] = useState(0);

  useEffect(() => {
    if(poolData) {
      setPool1isSmaller(Number(poolData.pool1Circulating) < Number(poolData.pool2Circulating));
    }
  }, [poolData])

  const { connector, isConnected } = useAccount();
  const [signer, setSigner] = useState();
  const { txnPending, setTxnPending } = useTxnPending();
  const { poolsClosed, setPoolsClosed } = usePoolsClosed();

  useEffect(() => {
      async function initialize() {
          const _signer = await connector.getSigner();
          setSigner(_signer);
      }

      if(connector) {
          console.log('connector', connector);
          initialize();
      }
  }, [connector])

  const handleUnstakeInputChange = (value, setFieldValue) => {
    setFieldValue("amount", value);
  
    const fee = parseFloat(value) * 0.1; // Calculate 10% fee
    setUnstakingFee(fee);
  };
  

  const formikStake = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required('Required')
        .min(0, 'Must be greater than or equal to 0')
        .max(balances && balances.lp, "Must be less than balance"),
    }),
    onSubmit: (values) => {
      console.log('Staking:', values.amount);
      stakePool3(signer, values.amount, setTxnPending);
    },
  });

  const formikUnstake = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required('Required')
        .min(0, 'Must be greater than or equal to 0')
        .max(balances && balances.pool3, 'Must be less than balance'),
    }),
    onSubmit: (values) => {
      console.log('Unstaking:', values.amount);
      unstakePool3(signer, values.amount, setTxnPending);
    },
    onChange: (values) => {
      console.log(values)
    }
  });

  const showTooltip = () => {
    document.getElementById('toolTipPool3').style.visibility = "visible";
  }

  const hideTooltip = () => {
    document.getElementById('toolTipPool3').style.visibility = "hidden";
  }


  const handleMaxStake = () => {
    formikStake.setFieldValue('amount', balances && balances.lp);
  };

  const handleMaxUnstake = () => {
    formikUnstake.setFieldValue('amount', balances && balances.pool3);
  };  

  return (
    <>
    <div className="Card pool3Card" style={{ '--border-color': poolColor }}>
      <div className="Card-title pool-title">
        <h1 className="Card-title-text">Pool {poolId + 1}</h1>
        <div className="Card-label">
            Liquidity Pool
        </div>
        <img className="questionIcon" width="25" id="pool3Icon" onMouseEnter={showTooltip} onMouseLeave={hideTooltip} src={questionMark} />
        <div className="toolTip" id="toolTipPool3"><h3>Pool 3</h3><p>Pool 3 is a unique opportunity for users to stake $PARTY-ETH LP tokens and earn increasing rewards over the first two weeks of the Pool Party launch. The rewards are distributed every 24 hours.</p></div>
      </div>
      <div>
      {isConnected ? <>
        <p style={{cursor:'pointer'}} onClick={() => window.open("https://migrate.poolpartyyy.com")}>
            Don't see your tokens? Click <span style={{ color: '#7EFF7B' }}>here</span> to Migrate!
            {/* Unstaking within 24 hours will <span style={{ color: '#FF7474' }}>forfeit</span> rewards!  */}
        </p>
      </> : 
      <p></p>
      }
      </div>
      <div className="parent-container-card">
        <div>
          <p
            onClick={() => setSelectedGroup(0)}
            className="child lpText"
            id={selectedGroup === 0 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Stake LP
          </p>
        </div>
        <div>
          <p
            onClick={() => setSelectedGroup(1)}
            className="child lpText"
            id={selectedGroup === 1 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Unstake LP
          </p>
        </div>
        {/* <div>
          <p
            onClick={() => setSelectedGroup(3)}
            className="child"
            id={selectedGroup === 3 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Favor
          </p>
        </div> */}
      </div>

      {selectedGroup === 0 && (
        <>
      <form onSubmit={formikStake.handleSubmit} className="input-container">
        <input
          type="text"
          name="amount"
          onChange={formikStake.handleChange}
          value={formikStake.values.amount}
          className={formikStake.errors.amount && formikStake.touched.amount ? 'input-error no-outline' : 'no-outline'}
        />
        {isConnected ? <>
        <div className="amount-container">
          <div>
            <p className="child available">{balances && balances.lp} PARTY-ETH LP</p>
          </div>
          <div>
            <p onClick={handleMaxStake} className="child max-button">MAX</p>
          </div>
        </div>
        </> : 
        <div className="amount-container">
          <div>
            <p className="child available"></p>
          </div>
          <div>
            <p className="child max-button"></p>
          </div>
        </div>
      }
        {/* User Stake Info */}
      {isConnected ? <>
        <div className="user-stake-info">
          <div className="user-stake-info-container">
          <ol className="pool-list">
            <li><span className="user-stake-info-label">Your stake</span><span className="stake-info-value">{balances && Number(balances.pool3).toLocaleString()} LP</span></li>
            <li><span className="user-stake-info-label">Earned</span><span className="stake-info-value">{balances && balances.pool3Earnings} PARTY</span></li>
            <li><span className="user-stake-info-label">Next potential reward</span><span className="stake-info-value">{poolData && balances && Number((0 * Number(balances.pool3)/Number(poolData.pool3Circulating)).toFixed(2)).toLocaleString()} PARTY</span></li>
            <li><span className="user-stake-info-label">APR</span><span className="user-stake-info-value">{poolData && poolData.pool3APY}%</span></li>
          </ol>
          </div>
        </div>
        </> :
           <div className="user-stake-info">
          <div className="user-stake-info-container">
          <ol className="pool-list">
            <li><span className="user-stake-info-label">APR</span><span className="user-stake-info-value">{poolData && poolData.pool3APY}%</span></li>
          </ol>
          </div>
        </div>
      }
        {/* Stake Button */}
        {/* {poolsClosed ? 
          <button className="locked-button" disabled>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <p className="button-text">Locked</p>
                <FaLock style={{ zIndex: 1 }} />
            </div>
            .
            <div className="button-text">
                00:23:45
            </div>
          </button> :
          <button type="submit" className="stake-button" style={{ '--background-color': poolColor }}>
            Stake
          </button>
        } */}
      {/*{isConnected ? <>
      <div className="buttonFlex">
        <button type="submit" className="stake-button" style={{ '--background-color': poolColor }}>
            Stake
        </button>
        {balances && Number(balances.pool3Earnings) > 0 && 
            <button onClick={() => claimPool3(signer, setTxnPending)} type="submit" className="stake-button">
                Claim
            </button>}
        </div></> :
      <button type="button" className="stake-button" style={{ background: 'grey', cursor: 'not-allowed' }}>
            Not Connected
        </button>
      }*/}
        


        {/* Stake Info */}
        <div className="stake-info">
          <div className="stake-info-container">
          <ol className="pool-list">
            <li><span className="stake-info-label">Total staked</span><span className="stake-info-value">{poolData && Number(poolData.pool3Circulating).toLocaleString()} LP</span></li>
          </ol>
        </div>
        </div>
      </form>
    </>
  )}
{selectedGroup === 1 && (
  <>
    <form onSubmit={formikUnstake.handleSubmit} className="input-container">
      <input
        type="text"
        name="amount"
        onChange={(e) => {
          formikUnstake.handleChange(e);
          handleUnstakeInputChange(e.target.value, formikUnstake.setFieldValue);
        }}
        value={formikUnstake.values.amount}
        className={formikUnstake.errors.amount && formikUnstake.touched.amount ? 'input-error no-outline' : 'no-outline'}
      />
      {isConnected ? <>
      <div className="amount-container">
        <div>
          <p className="child available">
            {balances && balances.pool3} PARTY-ETH LP
          </p>
        </div>
        <div>
          <p onClick={handleMaxUnstake} className="child max-button">
            MAX
          </p>
        </div>
      </div>
      </> :
      <div className="amount-container">
        <div>
          <p className="child available">
          </p>
        </div>
        <div>
          <p className="child max-button">
          </p>
        </div>
      </div>
    }

      {/* User Stake Info */}
      {isConnected ? <> 
      <div className="user-stake-info">
        <div className="user-stake-info-container">
        <ol className="pool-list">
          <li><span className='user-stake-info-label'>Your stake</span> <span className="stake-info-value">{balances && Number(balances.pool3).toLocaleString()} LP</span></li>
          <li><span className='user-stake-info-label'>Earned</span><span className="stake-info-value">{balances && balances.pool3Earnings} PARTY</span></li>
          <li><span className="stake-info-label">Next potential reward</span><span className="stake-info-value">{poolData && balances && Number((0 * Number(balances.pool3)/Number(poolData.pool3Circulating)).toFixed(2)).toLocaleString()} PARTY</span></li>
          <li><span className='user-stake-info-label'>APR</span><span className="user-stake-info-value">{poolData && poolData.pool3APY}%</span></li>
        </ol>
        </div>
      </div>
      </> :
      <div className="user-stake-info">
        <div className="user-stake-info-container">
        <ol className="pool-list">
          <li><span className='user-stake-info-label'>APR</span><span className="user-stake-info-value">{poolData && poolData.pool3APY}%</span></li>
        </ol>
        </div>
      </div>
    }

      {/* Unstake button */}
      {/* {poolsClosed ?
        <button className="locked-button" disabled>
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <p className="button-text">Locked</p>
              <FaLock style={{ zIndex: 1 }} />
          </div>
          .
          <div className="button-text">
              00:23:45
          </div>
        </button> :
        <button
          type="submit"
          className="unstake-button"
          style={{ '--background-color': poolColor }}
        >
          Unstake
        </button>
      } */}
    {isConnected ? <>
    <div className="buttonFlex">
    <button
        type="submit"
        className="unstake-button"
        style={{ '--background-color': poolColor }}
    >
        Unstake
    </button>
    {balances && Number(balances.pool3Earnings) > 0 && 
            <button onClick={() => claimPool3(signer, setTxnPending)} type="submit" className="stake-button">
                Claim
            </button>
    } </div></> :
    <button
        type="button"
        className="unstake-button"
        style={{ background: 'grey', cursor: 'not-allowed' }}
    >
        Not Connected
    </button>
    }


      <div className="stake-info">
        <div className="stake-info-container">
        <ol className="pool-list">
          <li><span className="stake-info-label">Total staked</span><span className="stake-info-value">{poolData && Number(poolData.pool3Circulating).toLocaleString()} LP</span></li>
        </ol>
        </div>
      </div>
    </form>
  </>
)}

</div>
</>
);

}

export default Card;
         
