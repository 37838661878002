import React, { useState, useEffect } from 'react';
import { useFormik, Formik, Form, Field, ErrorMessage } from "formik";
import { useAccount, useConnect } from 'wagmi';
import { stake, unstake, jump, contributeFavor } from '../../web3/web3';
import * as Yup from 'yup';
import './Card.css';
import { useTxnPending } from '../../contexts/TxnPendingContext';
import { usePoolsClosed } from '../../contexts/PoolsClosedContext';
import { FaPlus, FaMinus, FaLock } from 'react-icons/fa';
import CountdownTimer  from "../CountdownTimer/CountdownTimer";
import questionMark from "../../assets/images/question.png";

function Card(props) {
  const [pool1isSmaller, setPool1isSmaller] = useState();
  const { poolColor, balances, poolData, poolId, epoch } = props;
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [unstakingFee, setUnstakingFee] = useState(0);

  useEffect(() => {
    if(poolData) {
      setPool1isSmaller(Number(poolData.pool1Circulating) < Number(poolData.pool2Circulating));
    }
  }, [poolData])

  const { connector, isConnected } = useAccount();
  const [signer, setSigner] = useState();
  const { txnPending, setTxnPending } = useTxnPending();
  const { poolsClosed, setPoolsClosed } = usePoolsClosed();

  useEffect(() => {
      async function initialize() {
          const _signer = await connector.getSigner();
          setSigner(_signer);
      }

      if(connector) {
          console.log('connector', connector);
          initialize();
      }
  }, [connector])

  const handleUnstakeInputChange = (value, setFieldValue) => {
    setFieldValue("amount", value);
  
    const fee = parseFloat(value) * 0.01; // Calculate 1% fee
    setUnstakingFee(fee);
  };

    const handleFavorInputChange = (value, setFieldValue) => {
    setFieldValue("amount", value);
  };

  // const handleMaxStake = () => {
  //   formikStake.setFieldValue('amount', balances && balances.partyToken);
  // };

  // const handleMaxUnstake = () => {
  //   formikUnstake.setFieldValue('amount', balances && balances[`pool${poolId + 1}`]);
  // };

  // const handleMaxFavor = () => {
  //  formikContribute.setFieldValue('amount', balances && balances.favor);
  // };


  const formikStake = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required('Required')
        .min(0, 'Must be greater than or equal to 0')
        .max(balances && balances.partyToken, "Must be less than balance"),
    }),
    onSubmit: (values) => {
      console.log('Staking:', values.amount);
      stake(signer, values.amount, poolId, setTxnPending);
    },
  });

  const formikUnstake = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required('Required')
        .min(0, 'Must be greater than or equal to 0')
        .max(balances && balances[`pool${poolId + 1}`], 'Must be less than balance'),
    }),
    onSubmit: (values) => {
      console.log('Unstaking:', values.amount);
      unstake(signer, values.amount, poolId, setTxnPending);
    },
    onChange: (values) => {
      console.log(values)
    }
  });

  const formikContribute = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required('Required')
        .min(0, 'Must be greater than or equal to 0')
        .max(balances && balances.favor, 'Must be less than balance'),
    }),
    onSubmit: (values) => {
      console.log('Contributing:', values.amount);
      contributeFavor(signer, values.amount, poolId, setTxnPending);
    },
    onChange: (values) => {
      console.log(values)
    }
  });



     const showTooltipOne = () => {
    document.getElementById('toolTipPool1').style.visibility = "visible";
  }

  const hideTooltipOne = () => {
    document.getElementById('toolTipPool1').style.visibility = "hidden";
  }

  const showTooltipTwo = () => {
     document.getElementById('toolTipPool2').style.visibility = "visible";
  }

  const hideTooltipTwo = () => {
    document.getElementById('toolTipPool2').style.visibility = "hidden";
  }


  const handleMaxStake = () => {
    formikStake.setFieldValue('amount', balances && balances.partyToken);
  };

  const handleMaxUnstake = () => {
    formikUnstake.setFieldValue('amount', balances && balances[`pool${poolId + 1}`]);
  }; 

   const handleMaxFavor = () => {
    formikContribute.setFieldValue('amount', balances && balances.favor);
  }; 

  return (
    <>
    <div className="Card poolsCard" style={{ '--border-color': poolColor }}>
      <div className="Card-title pool-title">
        <h1 className="Card-title-text">Pool {poolId + 1}</h1>
        <div className="Card-label">
          {pool1isSmaller ?
              poolId === 0 ? 'Small Pool' : 'Big Pool' :
              poolId === 1 ? 'Small Pool' : 'Big Pool'
          }
        </div>
        {poolId === 0 ? <>
        <img className="questionIcon poolsIcon" id="pool1Icon" onMouseEnter={showTooltipOne} onMouseLeave={hideTooltipOne} width="25" src={questionMark} />
        <div className="toolTip" id="toolTipPool1"><h3>Pool 1</h3><p>Pool 1 and Pool 2 are designed to incentivize participants to stake their tokens and engage in a competitive staking environment, with the ultimate goal of creating a deflationary token with high liquidity. The use of a Cournot-style competition and dynamic APRs serve to promote efficient resource allocation and provide participants with a fair chance at earning rewards.</p></div>
        </> :
        <>
        <img className="questionIcon poolsIcon" id="pool2Icon" width="25" onMouseEnter={showTooltipTwo} onMouseLeave={hideTooltipTwo} src={questionMark} />
        <div className="toolTip" id="toolTipPool2"><h3>Pool 2</h3><p>Pool 1 and Pool 2 are designed to incentivize participants to stake their tokens and engage in a competitive staking environment, with the ultimate goal of creating a deflationary token with high liquidity. The use of a Cournot-style competition and dynamic APRs serve to promote efficient resource allocation and provide participants with a fair chance at earning rewards.</p></div>
        </>
      }
      </div>
      <div>
      {pool1isSmaller ?
              poolId === 0 ? ( 
                    <p>
                      This pool has a <span style={{ color: '#FF7474' }}>higher risk</span> and a{' '}
                      <span style={{ color: '#7EFF7B' }}>higher reward</span>
                    </p>
                  ) : (
                    <p>
                      This pool has a <span style={{ color: '#7EFF7B' }}>lower risk</span> and a{' '}
                      <span style={{ color: '#FF7474' }}>lower reward</span>
                    </p>
              ) :
              poolId === 1 ? (
                <p>
                  This pool has a <span style={{ color: '#FF7474' }}>higher risk</span> and a{' '}
                  <span style={{ color: '#7EFF7B' }}>higher reward</span>
                </p>
              ) : (
                <p>
                  This pool has a <span style={{ color: '#7EFF7B' }}>lower risk</span> and a{' '}
                  <span style={{ color: '#FF7474' }}>lower reward</span>
                </p>
              )
          }
      </div>
      <div className="parent-container-card">
        <div>
          <p
            onClick={() => setSelectedGroup(0)}
            className="child"
            id={selectedGroup === 0 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Stake
          </p>
        </div>
        <div>
          <p
            onClick={() => setSelectedGroup(1)}
            className="child"
            id={selectedGroup === 1 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Unstake
          </p>
        </div>
        <div>
          <p
            onClick={() => setSelectedGroup(2)}
            className="child"
            id={selectedGroup === 2 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Jump
          </p>
        </div>
        <div>
          <p
            onClick={() => setSelectedGroup(3)}
            className="child"
            id={selectedGroup === 3 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Favor
          </p>
        </div>
      </div>

      {selectedGroup === 0 && (
        <>
      <form onSubmit={formikStake.handleSubmit} className="input-container">
        <input
          type="text"
          name="amount"
          onChange={formikStake.handleChange}
          value={formikStake.values.amount}
          className={formikStake.errors.amount && formikStake.touched.amount ? 'input-error no-outline' : 'no-outline'}
        />
        
        {isConnected ? <>
        <div className="amount-container">
          <div>
            <p className="child available">{balances && balances.partyToken} PARTY</p>
          </div>
          <div>
            <p onClick={handleMaxStake} className="child max-button">MAX</p>
          </div>
          </div>
          </> :
          <div>
          <div>
           <p className="child available"></p>
          </div>
          <div>
            <p onClick={handleMaxStake} className="child max-button"></p>
          </div>
        </div>
        }
        {/* User Stake Info */}
        {isConnected ? <>
        <div className="user-stake-info">
          <div className="user-stake-info-container">
          <ol className="pool-list">
            <li><span className="user-stake-info-label">Your stake</span><span className="stake-info-value">{balances && Number(balances[`pool${poolId + 1}`]).toLocaleString()} PARTY</span></li>
            <li><span className="user-stake-info-label">Win APR</span>  <span className="user-stake-info-value">{poolData && (poolData[`pool${poolId + 1}Winnings`] * 365).toFixed(2)}%</span></li>
          </ol>
          </div>
        </div>
        </> :
        <div className="user-stake-info">
          <div className="user-stake-info-container">
          <ol className="pool-list">
            <li><span className="user-stake-info-label">Win APR</span><span className="user-stake-info-value">{poolData && (poolData[`pool${poolId + 1}Winnings`] * 365).toFixed(2)}%</span></li>
          </ol>
          </div>
        </div>
      }
        {/* Stake Button */}
        {poolsClosed ?
          <button className="locked-button" disabled>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <p className="button-text">Locked</p>
                <FaLock style={{ zIndex: 1 }} />
            </div>
            
            <div className="button-text">
              {epoch && <CountdownTimer targetTimestamp={new Date(epoch.endTime * 1000)} />}
            </div>
          </button> :
          isConnected ? <>
          <button type="submit" className="stake-button" style={{ '--background-color': poolColor }}>
            Stake
          </button>
          </> :
           <button type="button" disabled className="stake-button" style={{ background: 'grey', cursor: 'not-allowed' }}>
            Not Connected
          </button>
        
      }

        {/* Stake Info */}
        <div className="stake-info">
          <div className="stake-info-container">
          <ol className="pool-list">
            <li><span className="stake-info-label">Total staked</span><span className="stake-info-value">{poolData && Number(poolData[`pool${poolId + 1}Circulating`]).toLocaleString()} PARTY</span></li>
            <li><span className="stake-info-label">Potential gain this epoch</span><span className="stake-info-value">{poolData && poolData[`pool${poolId + 1}Winnings`]}%</span></li>
            <li><span className="stake-info-label">Current chance of winning</span><span className="stake-info-value">{poolData && poolData[`pool${poolId + 1}Odds`]}%</span></li>
           </ol> 
          </div>
        </div>
      </form>
    </>
  )}
{selectedGroup === 1 && (
  <>
    <form onSubmit={formikUnstake.handleSubmit} className="input-container">
      <input
        type="text"
        name="amount"
        onChange={(e) => {
          formikUnstake.handleChange(e);
          handleUnstakeInputChange(e.target.value, formikUnstake.setFieldValue);
        }}
        value={formikUnstake.values.amount}
        className={formikUnstake.errors.amount && formikUnstake.touched.amount ? 'input-error no-outline' : 'no-outline'}
      />
      {isConnected ? <>
      <div className="amount-container">
        <div>
          <p className="child available">
            {balances && balances[`pool${poolId + 1}`]} PARTY
          </p>
        </div>
        <div>
          <p onClick={handleMaxUnstake} className="child max-button">
            MAX
          </p>
        </div>
      </div>
       </> : 
        <div className="amount-container">
        <div>
          <p className="child available">
            
          </p>
        </div>
        <div>
          <p className="child max-button">
            
          </p>
        </div>
      </div>
     }
      {/* User Stake Info */}
      {isConnected ? <>
      <div className="user-stake-info">
        <div className="user-stake-info-container">
        <ol className="pool-list">
          <li><span className='user-stake-info-label'>Your stake</span><span className="stake-info-value">{balances && Number(balances[`pool${poolId + 1}`]).toLocaleString()} PARTY</span></li>
          <li><span className="user-stake-info-label">Unstaking fee (1%)</span><span className="user-stake-info-value">{unstakingFee ? Number(Number(unstakingFee).toFixed(2)).toLocaleString() : 0} PARTY</span></li>
        </ol>
        </div>
      </div>
      </> :
      <div className="user-stake-info">
        <div className="user-stake-info-container">
        <ol className="pool-list">
          <li><span className="user-stake-info-label">Unstaking fee (1%)</span><span className="user-stake-info-value">{unstakingFee ? Number(Number(unstakingFee).toFixed(2)).toLocaleString() : 0} PARTY</span></li>
        </ol>
        </div>
      </div>
    }

      {/* Unstake button */}
      {poolsClosed ?
        <button className="locked-button" disabled>
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <p className="button-text">Locked</p>
              <FaLock style={{ zIndex: 1 }} />
          </div>
          .
          <div className="button-text">
            {epoch && <CountdownTimer targetTimestamp={new Date(epoch.endTime * 1000)} />}
          </div>
        </button> :
        isConnected ? <>
        <button
          type="submit"
          className="unstake-button"
          style={{ '--background-color': poolColor }}
        >
          Unstake
        </button>
        </> :
        <button
          type="button"
          className="unstake-button"
          style={{ background: 'grey', cursor: 'not-allowed' }}
        >
          Not Connected
        </button>
      }

      <div className="stake-info">
        <div className="stake-info-container">
        <ol className="pool-list">
          <li><span className="stake-info-label">Total staked</span><span className="stake-info-value">{poolData && Number(poolData[`pool${poolId + 1}Circulating`]).toLocaleString()} PARTY</span></li>
          <li><span className="stake-info-label">Potential gain this epoch</span><span className="stake-info-value">{poolData && poolData[`pool${poolId + 1}Winnings`]}%</span></li>
          <li><span className="stake-info-label">Current chance of winning</span><span className="stake-info-value">{poolData && poolData[`pool${poolId + 1}Odds`]}%</span></li>
        </ol>
        </div>
      </div>
    </form>
  </>
)}
{selectedGroup === 2 && (
  <>
    <div className="jump-container">
        {isConnected ? <>
        {poolId === 0 ? <> 
         <button
          onClick={() => {
                jump(signer, 0, setTxnPending)
          }} disabled={txnPending} 
          className="unstake-button"
          style={{ '--background-color': poolColor }}
        >
          Jump to Pool 2
        </button>
        </> :
         <button
          onClick={() => {
                jump(signer, 1, setTxnPending)
          }} disabled={txnPending} 
          className="unstake-button"
          style={{ '--background-color': poolColor }}
        >
          Jump to Pool 1
        </button>
         }
        </> :
        <button
          type="button"
          className="unstake-button"
          style={{ background: 'grey', cursor: 'not-allowed' }}
        >
          Not Connected
        </button>
      }

      <div className="stake-info">
         <div className="user-stake-info-container">
        <ol className="pool-list">
          <li><span className='user-stake-info-label'>Your stake</span><span className="stake-info-value">{balances && Number(balances[`pool${poolId + 1}`]).toLocaleString()} PARTY</span></li>
          <li><span className="user-stake-info-label">Jump fee (1%)</span><span className="user-stake-info-value">{unstakingFee ? Number(Number(unstakingFee).toFixed(2)).toLocaleString() : 0} PARTY</span></li>
        </ol>
        </div>
      </div>
    </div>
  </>
)}
{selectedGroup === 3 && (
  <>
    <form onSubmit={formikContribute.handleSubmit} className="favor-container">
      <input
        type="text"
        name="amount"
        onChange={(e) => {
          formikContribute.handleChange(e);
          handleFavorInputChange(e.target.value, formikContribute.setFieldValue);
        }}
        value={formikContribute.values.amount}
        className={formikContribute.errors.amount && formikContribute.touched.amount ? 'input-error no-outline' : 'no-outline'}
      />
      {isConnected ? <>
      <div className="amount-container">
        <div>
          <p className="child available">
            {balances && balances.favor} FAVOR
          </p>
        </div>
        <div>
          <p onClick={handleMaxFavor} className="child max-button">
            MAX
          </p>
        </div>
      </div>
       </> : 
        <div className="amount-container">
        <div>
          <p className="child available">
            
          </p>
        </div>
        <div>
          <p className="child max-button">
            
          </p>
        </div>
      </div>
     }

      {isConnected ? <>
        <button
          type="submit"
          className="unstake-button"
          style={{ '--background-color': poolColor }}
        >
          Contribute
        </button>
        </> :
        <button
          type="button"
          className="unstake-button"
          style={{ background: 'grey', cursor: 'not-allowed' }}
        >
          Not Connected
        </button>
      }

      <div className="stake-info">
        <div className="stake-info-container">
        <ol className="pool-list">
          <li><span className="stake-info-label">Total staked</span><span className="stake-info-value">{poolData && poolData[`pool${poolId + 1}Favor`]} FAVOR</span></li>
          <li><span className="stake-info-label">Current chance of winning</span><span className="stake-info-value">{poolData && poolData[`pool${poolId + 1}Odds`]}%</span></li>
        </ol>
        </div>
      </div>
    </form>
  </>
)}
</div>
</>
);
}

export default Card;
         
