import React, { useState, useEffect } from 'react';
import { useFormik, Formik, Form, Field, ErrorMessage } from "formik";
import { useAccount, useConnect } from 'wagmi';
import { sportsStake, sportsUnstake } from '../../web3/web3';
import * as Yup from 'yup';
import {ethers} from 'ethers';
import './Card.css';
import { useTxnPending } from '../../contexts/TxnPendingContext';
import { FaPlus, FaMinus, FaLock } from 'react-icons/fa';
import CountdownTimer  from "../CountdownTimer/CountdownTimer";
import questionMark from "../../assets/images/question.png";
import timer from "../../assets/images/timer.png";
import nuggets from "../../assets/images/nuggets.png";
import heat from "../../assets/images/heat.png";

function SportsCard(props) {
  const [pool1isSmaller, setPool1isSmaller] = useState();
  const { poolColor, balances, poolData, poolId, epoch } = props;
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [unstakingFee, setUnstakingFee] = useState(0);
  const [stake, setStake] = useState(0);
  const { connector, isConnected } = useAccount();
  const [signer, setSigner] = useState();
  const { txnPending, setTxnPending } = useTxnPending();
  const [team, setTeam] = useState(0);
  const [teamIndex, setTeamIndex] = useState(0);

  useEffect(() => {
      async function initialize() {
          const _signer = await connector.getSigner();
          setSigner(_signer);
      }

      if(connector) {
          console.log('connector', connector);
          initialize();
      }
  }, [connector])

  const handleUnstakeInputChange = (value, setFieldValue) => {
    setFieldValue("amount", value);
  
    const fee = parseFloat(value) * 0.01; // Calculate 1% fee
    setUnstakingFee(fee);
  };


  const formikStake = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required('Required')
        .min(0, 'Must be greater than or equal to 0')
        .max(balances && balances.partyToken, "Must be less than balance"),
    }),
    onSubmit: (values) => {
      console.log('Staking:', values.amount);
      sportsStake(signer, values.amount, team, setTxnPending);
    },
  });

  const formikUnstake1 = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required('Required')
        .min(0, 'Must be greater than or equal to 0')
        .max(balances && balances[`userBetBalances`][selectedGroup*2], 'Must be less than balance'),
    }),
    onSubmit: (values) => {
      console.log('Unstaking:', values.amount);
      sportsUnstake(signer, values.amount, team, setTxnPending);
    },
    onChange: (values) => {
      console.log(values)
    }
  });

  const formikUnstake2 = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required('Required')
        .min(0, 'Must be greater than or equal to 0')
        .max(balances && balances[`userBetBalances`][(selectedGroup*2)+1], 'Must be less than balance'),
    }),
    onSubmit: (values) => {
      console.log('Unstaking:', values.amount);
      sportsUnstake(signer, values.amount, team, setTxnPending);
    },
    onChange: (values) => {
      console.log(values)
    }
  });



     const showTooltipOne = () => {
    document.getElementById('toolTipSports').style.visibility = "visible";
  }

  const hideTooltipOne = () => {
    document.getElementById('toolTipSports').style.visibility = "hidden";
  }


  const handleMaxStake = () => {
    formikStake.setFieldValue('amount', balances && balances.partyToken);
  };

  const handleMaxUnstake1 = () => {
    formikUnstake1.setFieldValue('amount', balances && balances[`userBetBalances`][selectedGroup*2]);
  }; 

    const handleMaxUnstake2 = () => {
    formikUnstake2.setFieldValue('amount', balances && balances[`userBetBalances`][(selectedGroup*2)+1]);
  }; 

  const switchStake = () => {
    setStake(0);
    document.getElementById('stakeText').style.border = "1px solid rgba(255,255,255,0.8)";
     document.getElementById('unstakeText').style.border = "none";
  }

  const switchUnstake = () => {
    setStake(1);
    document.getElementById('stakeText').style.border = "none";
     document.getElementById('unstakeText').style.border = "1px solid rgba(255,255,255,0.8)";
  }

  const selectTeam1 = () => {
    setTeam(selectedGroup*2);
    setTeamIndex(0)
    document.getElementById('team1').style.border = "3px solid rgba(89, 244, 93, 0.7)";
    document.getElementById('team2').style.border = "3px solid rgba(255,255,255,0.3)";
    document.getElementById('teampool1').style.border = "3px solid rgba(89, 244, 93, 0.7)";
    document.getElementById('teampool2').style.border = "3px solid rgba(255,255,255,0.3)";
    document.getElementById('ratioTeam1').style.color = "rgba(255,255,255,1.0)";
    document.getElementById('ratioTeam2').style.color = "rgba(255,255,255,0.3)";
  }

  const selectTeam2 = () => {
    setTeam((selectedGroup*2)+1);
    setTeamIndex(1)
    document.getElementById('team2').style.border = "3px solid rgba(89, 244, 93, 0.7)";
    document.getElementById('team1').style.border = "3px solid rgba(255,255,255,0.3)";
    document.getElementById('teampool2').style.border = "3px solid rgba(89, 244, 93, 0.7)";
    document.getElementById('teampool1').style.border = "3px solid rgba(255,255,255,0.3)";
    document.getElementById('ratioTeam1').style.color = "rgba(255,255,255,0.3)";
    document.getElementById('ratioTeam2').style.color = "rgba(255,255,255,1.0)";
  }

  console.log(new Date().getTime())
  console.log(poolData && poolData.startTime[selectedGroup]);

  return (
    <>
    <div className="Card poolsCard" style={{ '--border-color': poolColor }}>
      <div className="Card-title pool-title">
        <h1 className="Card-title-text">NBA Finals</h1>
        <img className="questionIcon poolsIcon" id="pool1Icon" onMouseEnter={showTooltipOne} onMouseLeave={hideTooltipOne} width="25" src={questionMark} />
        <div className="toolTip" id="toolTipSports"><h3>Sports Betting</h3><p>Bet on your favorite teams in NBA, NHL, NFL and win PARTY based on the odds calculated from PARTY amount bet on each team.</p></div>
      </div>
      <div className="parent-container-card">
        <div>
          <p
            onClick={() => {setSelectedGroup(0); setTeam(0*2); setTeamIndex(0); setStake(0)}}
            className="child"
            id={selectedGroup === 0 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Game 1
          </p>
        </div>
        <div>
          <p
            onClick={() => {setSelectedGroup(1); setTeam(1*2); setTeamIndex(0); setStake(0)}}
            className="child"
            id={selectedGroup === 1 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Game 2
          </p>
        </div>
        <div>
          <p
            onClick={() => {setSelectedGroup(2); setTeam(2*2); setTeamIndex(0); setStake(0)}}
            className="child"
            id={selectedGroup === 2 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Game 3
          </p>
        </div>
          <div>
          <p
            onClick={() => {setSelectedGroup(3); setTeam(3*2); setTeamIndex(0); setStake(0)}} 
            className="child"
            id={selectedGroup === 3 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Game 4
          </p>
        </div>
      </div>

      {selectedGroup === 0 && (
        <>
           <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}><p style={{padding: "0", margin: "0", color: "white", fontWeight: "900", fontSize: "18px"}}>Heat @ Nuggets</p><span style={{opacity: "0.8", display: 'flex', alignItems: 'center', color: 'white'}}><img src={timer}/>Finished</span></div>
        <div className="team-div">
          <div className="team-info-container">
           <div onClick={selectTeam1} className="bet-info pointer"><img id="team1" className="team-image" src={heat} /><p id="teampool1" className="team-pool">Pool 1 {poolData && Number(poolData[`sportsCirculatingSupply`][selectedGroup*2]).toLocaleString()} PARTY</p><p id="ratioTeam1">Loss</p></div>
           <div onClick={selectTeam2} className="bet-info pointer"><img id="team2" className="team-image" src={nuggets} /><p id="teampool2" className="team-pool">Pool 2 {poolData && Number(poolData[`sportsCirculatingSupply`][(selectedGroup*2)+1]).toLocaleString()} PARTY</p><p id="ratioTeam2">Win</p></div>
          </div>
        </div>
           {stake == 1 ? <>
    
      </>:
      <>
      {teamIndex == 0 ? 
      <>
      <p>Heat</p>
         <form onSubmit={formikUnstake1.handleSubmit} className="input-container">
        <input
          type="text"
          name="amount"
          onChange={formikUnstake1.handleChange}
          value={formikUnstake1.values.amount}
          className={formikUnstake1.errors.amount && formikUnstake1.touched.amount ? 'input-error no-outline' : 'no-outline'}
        />
        
        {isConnected ? <>
        <div className="amount-container">
          <div>
            <p className="child available">{balances && balances[`userBetBalances`][selectedGroup*2]} PARTY</p>
          </div>
          <div>
            <p onClick={handleMaxUnstake1} className="child max-button">MAX</p>
          </div>
          </div>
          </> :
          <div>
          <div>
           <p className="child available"></p>
          </div>
          <div>
            <p onClick={handleMaxUnstake1} className="child max-button"></p>
          </div>
        </div>
        }
        {/* Stake Button */}
        {1 == 0 ?
          <button className="locked-button" disabled>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <p className="button-text">Locked</p>
                <FaLock style={{ zIndex: 1 }} />
            </div>
            
            <div className="button-text">
              {epoch && <CountdownTimer targetTimestamp={new Date(epoch.endTime * 1000)} />}
            </div>
          </button> :
          isConnected ? <>
          <button type="submit" className="stake-button" style={{ '--background-color': poolColor }}>
            Unstake
          </button>
          </> :
           <button type="button" disabled className="stake-button" style={{ background: 'grey', cursor: 'not-allowed' }}>
            Not Connected
          </button>
        
      }

        {/* Stake Info */}
      
        <div className="bets-div">
        {isConnected ? <> 
        {balances && balances[`userBetBalances`][selectedGroup*2] > 0 ? <> <p className="yourBet">Your Heat Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][selectedGroup*2]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][selectedGroup*2]*poolData[`decimalOdds`][selectedGroup*2]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={heat} /></div></div>
          </div></>:<></>}
       {balances && balances[`userBetBalances`][(selectedGroup*2)+1] > 0 ? <> <p className="yourBet">Your Nugget Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][(selectedGroup*2)+1]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][(selectedGroup*2)+1]*poolData[`decimalOdds`][(selectedGroup*2)+1]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={nuggets} /></div></div>
          </div></>:<></>}
          </> : <> </>}
          
          {/*<p className="upcomingSport">Upcoming Games</p>
           <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>
              <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>*/}
        </div>
      </form>
      </>
      :
      <>
      <p>Nuggets</p>
         <form onSubmit={formikUnstake2.handleSubmit} className="input-container">
        <input
          type="text"
          name="amount"
          onChange={formikUnstake2.handleChange}
          value={formikUnstake2.values.amount}
          className={formikUnstake2.errors.amount && formikUnstake2.touched.amount ? 'input-error no-outline' : 'no-outline'}
        />
        
        {isConnected ? <>
        <div className="amount-container">
          <div>
            <p className="child available">{balances && balances[`userBetBalances`][(selectedGroup*2)+1]} PARTY</p>
          </div>
          <div>
            <p onClick={handleMaxUnstake2} className="child max-button">MAX</p>
          </div>
          </div>
          </> :
          <div>
          <div>
           <p className="child available"></p>
          </div>
          <div>
            <p onClick={handleMaxUnstake2} className="child max-button"></p>
          </div>
        </div>
        }
        {/* Stake Button */}
        {1 == 0 ?

          <button className="locked-button" disabled>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <p className="button-text">Locked</p>
                <FaLock style={{ zIndex: 1 }} />
            </div>
            
            <div className="button-text">
              {epoch && <CountdownTimer targetTimestamp={new Date(epoch.endTime * 1000)} />}
            </div>
          </button> :
          isConnected ? <>
          <button type="submit" className="stake-button" style={{ '--background-color': poolColor }}>
            Unstake
          </button>
          </> :
           <button type="button" disabled className="stake-button" style={{ background: 'grey', cursor: 'not-allowed' }}>
            Not Connected
          </button>
        
      }

        {/* Stake Info */}
      
        <div className="bets-div">
        {isConnected ? <> 
        {balances && balances[`userBetBalances`][selectedGroup*2] > 0 ? <> <p className="yourBet">Your Heat Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][selectedGroup*2]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][selectedGroup*2]*poolData[`decimalOdds`][selectedGroup*2]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={heat} /></div></div>
          </div></>:<></>}
       {balances && balances[`userBetBalances`][(selectedGroup*2)+1] > 0 ? <> <p className="yourBet">Your Nugget Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][(selectedGroup*2)+1]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][(selectedGroup*2)+1]*poolData[`decimalOdds`][(selectedGroup*2)+1]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={nuggets} /></div></div>
          </div></>:<></>}
          </> : <> </>}
          
          {/*<p className="upcomingSport">Upcoming Games</p>
           <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>
              <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>*/}
        </div>
      </form>
      </>}
         
      </>}
      
    </>
  )}
{selectedGroup === 1 && (
  <>
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}><p style={{padding: "0", margin: "0", color: "white", fontWeight: "900", fontSize: "18px"}}>Heat @ Nuggets</p><span style={{opacity: "0.8", display: 'flex', alignItems: 'center', color: 'white'}}><img src={timer}/>Finished</span></div>
        <div className="team-div">
          <div className="team-info-container">
           <div onClick={selectTeam1} className="bet-info pointer"><img id="team1" className="team-image" src={heat} /><p id="teampool1" className="team-pool">Pool 1 {poolData && Number(poolData[`sportsCirculatingSupply`][selectedGroup*2]).toLocaleString()} PARTY</p><p id="ratioTeam1">Win</p></div>
           <div onClick={selectTeam2} className="bet-info pointer"><img id="team2" className="team-image" src={nuggets} /><p id="teampool2" className="team-pool">Pool 2 {poolData && Number(poolData[`sportsCirculatingSupply`][(selectedGroup*2)+1]).toLocaleString()} PARTY</p><p id="ratioTeam2">Loss</p></div>
          </div>
        </div>
           {stake == 1 ? <>
    
      </>:
      <>
      {teamIndex == 0 ? 
      <>
      <p>Heat</p>
         <form onSubmit={formikUnstake1.handleSubmit} className="input-container">
        <input
          type="text"
          name="amount"
          onChange={formikUnstake1.handleChange}
          value={formikUnstake1.values.amount}
          className={formikUnstake1.errors.amount && formikUnstake1.touched.amount ? 'input-error no-outline' : 'no-outline'}
        />
        
        {isConnected ? <>
        <div className="amount-container">
          <div>
            <p className="child available">{balances && balances[`userBetBalances`][selectedGroup*2]} PARTY</p>
          </div>
          <div>
            <p onClick={handleMaxUnstake1} className="child max-button">MAX</p>
          </div>
          </div>
          </> :
          <div>
          <div>
           <p className="child available"></p>
          </div>
          <div>
            <p onClick={handleMaxUnstake1} className="child max-button"></p>
          </div>
        </div>
        }
        {/* Stake Button */}
        {1 == 0 ?
          <button className="locked-button" disabled>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <p className="button-text">Locked</p>
                <FaLock style={{ zIndex: 1 }} />
            </div>
            
            <div className="button-text">
              {epoch && <CountdownTimer targetTimestamp={new Date(epoch.endTime * 1000)} />}
            </div>
          </button> :
          isConnected ? <>
          <button type="submit" className="stake-button" style={{ '--background-color': poolColor }}>
            Unstake
          </button>
          </> :
           <button type="button" disabled className="stake-button" style={{ background: 'grey', cursor: 'not-allowed' }}>
            Not Connected
          </button>
        
      }

        {/* Stake Info */}
      
        <div className="bets-div">
        {isConnected ? <> 
        {balances && balances[`userBetBalances`][selectedGroup*2] > 0 ? <> <p className="yourBet">Your Heat Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][selectedGroup*2]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][selectedGroup*2]*poolData[`decimalOdds`][selectedGroup*2]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={heat} /></div></div>
          </div></>:<></>}
       {balances && balances[`userBetBalances`][(selectedGroup*2)+1] > 0 ? <> <p className="yourBet">Your Nugget Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][(selectedGroup*2)+1]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][(selectedGroup*2)+1]*poolData[`decimalOdds`][(selectedGroup*2)+1]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={nuggets} /></div></div>
          </div></>:<></>}
          </> : <> </>}
          
          {/*<p className="upcomingSport">Upcoming Games</p>
           <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>
              <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>*/}
        </div>
      </form>
      </>
      :
      <>
      <p>Nuggets</p>
         <form onSubmit={formikUnstake2.handleSubmit} className="input-container">
        <input
          type="text"
          name="amount"
          onChange={formikUnstake2.handleChange}
          value={formikUnstake2.values.amount}
          className={formikUnstake2.errors.amount && formikUnstake2.touched.amount ? 'input-error no-outline' : 'no-outline'}
        />
        
        {isConnected ? <>
        <div className="amount-container">
          <div>
            <p className="child available">{balances && balances[`userBetBalances`][(selectedGroup*2)+1]} PARTY</p>
          </div>
          <div>
            <p onClick={handleMaxUnstake2} className="child max-button">MAX</p>
          </div>
          </div>
          </> :
          <div>
          <div>
           <p className="child available"></p>
          </div>
          <div>
            <p onClick={handleMaxUnstake2} className="child max-button"></p>
          </div>
        </div>
        }
        {/* Stake Button */}
        {1 == 0 ?

          <button className="locked-button" disabled>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <p className="button-text">Locked</p>
                <FaLock style={{ zIndex: 1 }} />
            </div>
            
            <div className="button-text">
              {epoch && <CountdownTimer targetTimestamp={new Date(epoch.endTime * 1000)} />}
            </div>
          </button> :
          isConnected ? <>
          <button type="submit" className="stake-button" style={{ '--background-color': poolColor }}>
            Unstake
          </button>
          </> :
           <button type="button" disabled className="stake-button" style={{ background: 'grey', cursor: 'not-allowed' }}>
            Not Connected
          </button>
        
      }

        {/* Stake Info */}
      
        <div className="bets-div">
        {isConnected ? <> 
        {balances && balances[`userBetBalances`][selectedGroup*2] > 0 ? <> <p className="yourBet">Your Heat Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][selectedGroup*2]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][selectedGroup*2]*poolData[`decimalOdds`][selectedGroup*2]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={heat} /></div></div>
          </div></>:<></>}
       {balances && balances[`userBetBalances`][(selectedGroup*2)+1] > 0 ? <> <p className="yourBet">Your Nugget Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][(selectedGroup*2)+1]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][(selectedGroup*2)+1]*poolData[`decimalOdds`][(selectedGroup*2)+1]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={nuggets} /></div></div>
          </div></>:<></>}
          </> : <> </>}
          
          {/*<p className="upcomingSport">Upcoming Games</p>
           <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>
              <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>*/}
        </div>
      </form>
      </>}
         
      </>}
      
  </>
)}
{selectedGroup === 2 && (
  <>
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}><p style={{padding: "0", margin: "0", color: "white", fontWeight: "900", fontSize: "18px"}}>Heat @ Nuggets</p><span style={{opacity: "0.8", display: 'flex', alignItems: 'center', color: 'white'}}><img src={timer}/>Finished</span></div>
        <div className="team-div">
          <div className="team-info-container">
           <div onClick={selectTeam1} className="bet-info pointer"><img id="team1" className="team-image" src={heat} /><p id="teampool1" className="team-pool">Pool 1 {poolData && Number(poolData[`sportsCirculatingSupply`][selectedGroup*2]).toLocaleString()} PARTY</p><p id="ratioTeam1">Loss</p></div>
           <div onClick={selectTeam2} className="bet-info pointer"><img id="team2" className="team-image" src={nuggets} /><p id="teampool2" className="team-pool">Pool 2 {poolData && Number(poolData[`sportsCirculatingSupply`][(selectedGroup*2)+1]).toLocaleString()} PARTY</p><p id="ratioTeam2">Win</p></div>
          </div>
        </div>
           {stake == 1 ? <>
    
      </>:
      <>
      {teamIndex == 0 ? 
      <>
      <p>Heat</p>
         <form onSubmit={formikUnstake1.handleSubmit} className="input-container">
        <input
          type="text"
          name="amount"
          onChange={formikUnstake1.handleChange}
          value={formikUnstake1.values.amount}
          className={formikUnstake1.errors.amount && formikUnstake1.touched.amount ? 'input-error no-outline' : 'no-outline'}
        />
        
        {isConnected ? <>
        <div className="amount-container">
          <div>
            <p className="child available">{balances && balances[`userBetBalances`][selectedGroup*2]} PARTY</p>
          </div>
          <div>
            <p onClick={handleMaxUnstake1} className="child max-button">MAX</p>
          </div>
          </div>
          </> :
          <div>
          <div>
           <p className="child available"></p>
          </div>
          <div>
            <p onClick={handleMaxUnstake1} className="child max-button"></p>
          </div>
        </div>
        }
        {/* Stake Button */}
        {1 == 0 ?
          <button className="locked-button" disabled>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <p className="button-text">Locked</p>
                <FaLock style={{ zIndex: 1 }} />
            </div>
            
            <div className="button-text">
              {epoch && <CountdownTimer targetTimestamp={new Date(epoch.endTime * 1000)} />}
            </div>
          </button> :
          isConnected ? <>
          <button type="submit" className="stake-button" style={{ '--background-color': poolColor }}>
            Unstake
          </button>
          </> :
           <button type="button" disabled className="stake-button" style={{ background: 'grey', cursor: 'not-allowed' }}>
            Not Connected
          </button>
        
      }

        {/* Stake Info */}
      
        <div className="bets-div">
        {isConnected ? <> 
        {balances && balances[`userBetBalances`][selectedGroup*2] > 0 ? <> <p className="yourBet">Your Heat Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][selectedGroup*2]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][selectedGroup*2]*poolData[`decimalOdds`][selectedGroup*2]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={heat} /></div></div>
          </div></>:<></>}
       {balances && balances[`userBetBalances`][(selectedGroup*2)+1] > 0 ? <> <p className="yourBet">Your Nugget Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][(selectedGroup*2)+1]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][(selectedGroup*2)+1]*poolData[`decimalOdds`][(selectedGroup*2)+1]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={nuggets} /></div></div>
          </div></>:<></>}
          </> : <> </>}
          
          {/*<p className="upcomingSport">Upcoming Games</p>
           <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>
              <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>*/}
        </div>
      </form>
      </>
      :
      <>
      <p>Nuggets</p>
         <form onSubmit={formikUnstake2.handleSubmit} className="input-container">
        <input
          type="text"
          name="amount"
          onChange={formikUnstake2.handleChange}
          value={formikUnstake2.values.amount}
          className={formikUnstake2.errors.amount && formikUnstake2.touched.amount ? 'input-error no-outline' : 'no-outline'}
        />
        
        {isConnected ? <>
        <div className="amount-container">
          <div>
            <p className="child available">{balances && balances[`userBetBalances`][(selectedGroup*2)+1]} PARTY</p>
          </div>
          <div>
            <p onClick={handleMaxUnstake2} className="child max-button">MAX</p>
          </div>
          </div>
          </> :
          <div>
          <div>
           <p className="child available"></p>
          </div>
          <div>
            <p onClick={handleMaxUnstake2} className="child max-button"></p>
          </div>
        </div>
        }
        {/* Stake Button */}
        {1 == 0 ?

          <button className="locked-button" disabled>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <p className="button-text">Locked</p>
                <FaLock style={{ zIndex: 1 }} />
            </div>
            
            <div className="button-text">
              {epoch && <CountdownTimer targetTimestamp={new Date(epoch.endTime * 1000)} />}
            </div>
          </button> :
          isConnected ? <>
          <button type="submit" className="stake-button" style={{ '--background-color': poolColor }}>
            Unstake
          </button>
          </> :
           <button type="button" disabled className="stake-button" style={{ background: 'grey', cursor: 'not-allowed' }}>
            Not Connected
          </button>
        
      }

        {/* Stake Info */}
      
        <div className="bets-div">
        {isConnected ? <> 
        {balances && balances[`userBetBalances`][selectedGroup*2] > 0 ? <> <p className="yourBet">Your Heat Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][selectedGroup*2]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][selectedGroup*2]*poolData[`decimalOdds`][selectedGroup*2]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={heat} /></div></div>
          </div></>:<></>}
       {balances && balances[`userBetBalances`][(selectedGroup*2)+1] > 0 ? <> <p className="yourBet">Your Nugget Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][(selectedGroup*2)+1]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][(selectedGroup*2)+1]*poolData[`decimalOdds`][(selectedGroup*2)+1]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={nuggets} /></div></div>
          </div></>:<></>}
          </> : <> </>}
          
          {/*<p className="upcomingSport">Upcoming Games</p>
           <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>
              <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>*/}
        </div>
      </form>
      </>}
         
      </>}
      
  </>
)}
{selectedGroup === 3 && (
  <>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}><p style={{padding: "0", margin: "0", color: "white", fontWeight: "900", fontSize: "18px"}}>Heat @ Nuggets</p><span style={{opacity: "0.8", display: 'flex', alignItems: 'center', color: 'white'}}><img src={timer}/>Finished</span></div>
        <div className="team-div">
          <div className="team-info-container">
           <div onClick={selectTeam1} className="bet-info pointer"><img id="team1" className="team-image" src={heat} /><p id="teampool1" className="team-pool">Pool 1 {poolData && Number(poolData[`sportsCirculatingSupply`][selectedGroup*2]).toLocaleString()} PARTY</p><p id="ratioTeam1">Loss</p></div>
           <div onClick={selectTeam2} className="bet-info pointer"><img id="team2" className="team-image" src={nuggets} /><p id="teampool2" className="team-pool">Pool 2 {poolData && Number(poolData[`sportsCirculatingSupply`][(selectedGroup*2)+1]).toLocaleString()} PARTY</p><p id="ratioTeam2">Win</p></div>
          </div>
        </div>
           {stake == 1 ? <>
    
      </>:
      <>
      {teamIndex == 0 ? 
      <>
      <p>Heat</p>
         <form onSubmit={formikUnstake1.handleSubmit} className="input-container">
        <input
          type="text"
          name="amount"
          onChange={formikUnstake1.handleChange}
          value={formikUnstake1.values.amount}
          className={formikUnstake1.errors.amount && formikUnstake1.touched.amount ? 'input-error no-outline' : 'no-outline'}
        />
        
        {isConnected ? <>
        <div className="amount-container">
          <div>
            <p className="child available">{balances && balances[`userBetBalances`][selectedGroup*2]} PARTY</p>
          </div>
          <div>
            <p onClick={handleMaxUnstake1} className="child max-button">MAX</p>
          </div>
          </div>
          </> :
          <div>
          <div>
           <p className="child available"></p>
          </div>
          <div>
            <p onClick={handleMaxUnstake1} className="child max-button"></p>
          </div>
        </div>
        }
        {/* Stake Button */}
        {1 == 0 ?
          <button className="locked-button" disabled>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <p className="button-text">Locked</p>
                <FaLock style={{ zIndex: 1 }} />
            </div>
            
            <div className="button-text">
              {epoch && <CountdownTimer targetTimestamp={new Date(epoch.endTime * 1000)} />}
            </div>
          </button> :
          isConnected ? <>
          <button type="submit" className="stake-button" style={{ '--background-color': poolColor }}>
            Unstake
          </button>
          </> :
           <button type="button" disabled className="stake-button" style={{ background: 'grey', cursor: 'not-allowed' }}>
            Not Connected
          </button>
        
      }

        {/* Stake Info */}
      
        <div className="bets-div">
        {isConnected ? <> 
        {balances && balances[`userBetBalances`][selectedGroup*2] > 0 ? <> <p className="yourBet">Your Heat Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][selectedGroup*2]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][selectedGroup*2]*poolData[`decimalOdds`][selectedGroup*2]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={heat} /></div></div>
          </div></>:<></>}
       {balances && balances[`userBetBalances`][(selectedGroup*2)+1] > 0 ? <> <p className="yourBet">Your Nugget Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][(selectedGroup*2)+1]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][(selectedGroup*2)+1]*poolData[`decimalOdds`][(selectedGroup*2)+1]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={nuggets} /></div></div>
          </div></>:<></>}
          </> : <> </>}
          
          {/*<p className="upcomingSport">Upcoming Games</p>
           <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>
              <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>*/}
        </div>
      </form>
      </>
      :
      <>
      <p>Nuggets</p>
         <form onSubmit={formikUnstake2.handleSubmit} className="input-container">
        <input
          type="text"
          name="amount"
          onChange={formikUnstake2.handleChange}
          value={formikUnstake2.values.amount}
          className={formikUnstake2.errors.amount && formikUnstake2.touched.amount ? 'input-error no-outline' : 'no-outline'}
        />
        
        {isConnected ? <>
        <div className="amount-container">
          <div>
            <p className="child available">{balances && balances[`userBetBalances`][(selectedGroup*2)+1]} PARTY</p>
          </div>
          <div>
            <p onClick={handleMaxUnstake2} className="child max-button">MAX</p>
          </div>
          </div>
          </> :
          <div>
          <div>
           <p className="child available"></p>
          </div>
          <div>
            <p onClick={handleMaxUnstake2} className="child max-button"></p>
          </div>
        </div>
        }
        {/* Stake Button */}
        {1 == 0 ?

          <button className="locked-button" disabled>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <p className="button-text">Locked</p>
                <FaLock style={{ zIndex: 1 }} />
            </div>
            
            <div className="button-text">
              {epoch && <CountdownTimer targetTimestamp={new Date(epoch.endTime * 1000)} />}
            </div>
          </button> :
          isConnected ? <>
          <button type="submit" className="stake-button" style={{ '--background-color': poolColor }}>
            Unstake
          </button>
          </> :
           <button type="button" disabled className="stake-button" style={{ background: 'grey', cursor: 'not-allowed' }}>
            Not Connected
          </button>
        
      }

        {/* Stake Info */}
      
        <div className="bets-div">
        {isConnected ? <> 
        {balances && balances[`userBetBalances`][selectedGroup*2] > 0 ? <> <p className="yourBet">Your Heat Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][selectedGroup*2]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][selectedGroup*2]*poolData[`decimalOdds`][selectedGroup*2]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={heat} /></div></div>
          </div></>:<></>}
       {balances && balances[`userBetBalances`][(selectedGroup*2)+1] > 0 ? <> <p className="yourBet">Your Nugget Bet:</p>
          <div className="bet-info-container">
           <div className="bet-info first-bet-div"><p className="bets-text bets-left">NBA Playoffs</p><p  className="bets-text bets-left opacity">Heat vs.</p><p  className="bets-text bets-left opacity">Nuggets</p></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">{balances && balances[`userBetBalances`][(selectedGroup*2)+1]} PARTY</p><p  className="bets-text bets-right"><span style={{color: '#39FF14'}}>-></span> {poolData && (balances && balances[`userBetBalances`][(selectedGroup*2)+1]*poolData[`decimalOdds`][(selectedGroup*2)+1]).toFixed(2)}</p><p  className="bets-text bets-right opacity"></p></div><div><img className="bets-image" src={nuggets} /></div></div>
          </div></>:<></>}
          </> : <> </>}
          
          {/*<p className="upcomingSport">Upcoming Games</p>
           <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>
              <div className="upcoming-info-container">
           <div className="bet-info first-bet-div" style={{display: "flex"}}><div><img className="bets-image" src={heat} /></div><div><p className="bets-text bets-left">heat</p><p  className="bets-text bets-left opacity">Celtics</p></div></div>
           <div className="bet-info" style={{display: "flex"}}><div><p className="bets-text bets-right">Philidelphia</p><p  className="bets-text bets-right opacity">76ers</p></div><div><img className="bets-image" src={nuggets} /></div></div>
             </div>*/}
        </div>
      </form>
      </>}
         
      </>}
      
  </>
)}
</div>
</>
);
}

export default SportsCard;
         
