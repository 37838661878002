import React, { useEffect, useState } from 'react';
import './Card.css';
import favorLogo from '../../assets/images/Candy.png';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { Formik } from 'formik';
import { useAccount, isConnected } from 'wagmi'
import { getFavorPrice, buyFavor, getFavorPriceRPC } from '../../web3/web3';
import { useTxnPending } from '../../contexts/TxnPendingContext';
import questionMark from "../../assets/images/question.png";

function BuyCard(props) {
    const { poolColor, balances } = props
    const { connector, isConnected } = useAccount();
    const [price, setPrice] = useState();
    const [signer, setSigner] = useState();
    const { txnPending, setTxnPending } = useTxnPending();

    useEffect(() => {
        async function initialize() {
            const _signer = await connector.getSigner();
            console.log('signer', _signer)
            setPrice(await getFavorPrice(_signer, 1));
            console.log('price', price);
            setSigner(_signer);
        }

        async function notConnectInit(){
            setPrice(await getFavorPriceRPC(1));
        }

        // console.log('signer: ', signer)
        if(connector) {
        console.log('connector', connector);
        initialize();
        }else{
            notConnectInit();
        }
    }, [connector, setPrice])

    const showTooltip = () => {
    document.getElementById('toolTipFavorBuy').style.visibility = "visible";
  }

  const hideTooltip = () => {
    document.getElementById('toolTipFavorBuy').style.visibility = "hidden";
  }


    return (
        <>
        <Formik
            initialValues={{ amount: 1 }}
            onSubmit={(values, { setSubmitting }) => {
                console.log('values', values);
                buyFavor(signer, values.amount, setTxnPending)
            }}
            onChange={(values) => async () => {
                setPrice(await getFavorPrice(signer, values));
            }}
  

        >
            {/* Input */}
            {({ handleSubmit, isSubmitting, values, handleChange, setFieldValue }) => {
                const increment = async () => {
                    setPrice(await getFavorPrice(signer, values.amount + 1));
                    setFieldValue("amount", values.amount + 1);
                };

                const decrement = async () => {
                    if (values.amount > 0) {
                        setPrice(await getFavorPrice(signer, values.amount - 1));
                        setFieldValue("amount", values.amount - 1);
                    }
                };
                return (
                    <div className="Card favorBuyCard" style={{ '--border-color': poolColor }}>
                        <div className="Buy-Box">
                          <div className="Candy-Box">
                          <img src={favorLogo} className="favorLogo" />
                          </div>
                            <div className="Buy-Box-Text-container">
                                <div className="Buy-Box-Title-Div">
                                <h4 className="Buy-Box-Title">
                                    Buy FAVOR
                                </h4>
                                <img className="questionIcon" id="buyIcon" onMouseEnter={showTooltip} onMouseLeave={hideTooltip} width="25" src={questionMark} />
                                 <div className="toolTip" id="toolTipFavorBuy"><h3>Buy Favor</h3><p>The pricing of Favor follows a VRGDA (variable rate gradual Dutch auction). This means that Favor starts at a price of 5,000 $PARTY, and the protocol adjusts the price of Favor to target 200 sales per day. If there are no sales in 24 hours, the price of Favor will decay roughly 50%.</p></div>
                                </div>
                                <div className="Buy-Box-Text-Div">
                                <p className="Buy-Box-Text">
                                    Contribute $FAVOR to a pool to increase its chances of winning
                                </p>
                                </div>
                            </div>
                            
                        </div>
                   {isConnected ? <>
                        <div className="Buy-Box-Input-container">
     
                            <div className="Buy-Box-Input-Wrapper">
                                <p className="Favor-Input-Label-Text">Quantity of FAVOR:</p>
                                <div className="Favor-Input-Container">
                                    <button
                                        disabled={values.amount === 1}
                                        className="Favor-Input-Button"
                                        onClick={decrement}
                                    >
                                        <FaMinus />
                                    </button>
                                    <input
                                        disabled
                                        type="number"
                                        min="0"
                                        className="Buy-Box-Input Favor-Input"
                                        name="amount"
                                        value={values.amount}
                                    />
                                    <button className="Favor-Input-Button" onClick={increment}>
                                        <FaPlus />
                                    </button>
                                </div>
                            </div>
                            

                            <div className="Total-Input-Wrapper">
                                <p className="Favor-Input-Label-Text">Total:</p>
                                <div className="total-container">
                                    <input value={price && Number(price).toLocaleString()} type="text" className="Buy-Box-Input" />
                                </div>
                            </div>
                        </div>
                        <button onClick={handleSubmit} className='Purchase-Button' type="submit" disabled={txnPending} >
                            Purchase
                        </button>
        

                        <div className='stake-info'>
                            <div className='stake-info-container'>
                            <ol className="favor-list">
                                <li><p className='favor-stake-info-label'>Price per Favor</p><p className='stake-info-value partyAmount'>{price && (Number(price) / values.amount).toLocaleString()} PARTY</p></li>
                                <li><p className='favor-stake-info-label'>PARTY Balance</p><p className='stake-info-value partyAmount'>{balances && Number(Number(balances.partyToken).toFixed(2)).toLocaleString()} PARTY</p></li>
                                <li><p className='favor-stake-info-label'>FAVOR Balance</p><p className='stake-info-value partyAmount'>{balances && balances.favor}</p></li>
                            </ol>
                            </div>
                        </div>
                        </> :
                          <div className='stake-info'>
                            <div className='stake-info-container'>
                            <ol className="favor-list">
                                <li><span className='favor-stake-info-label'>Price per Favor</span><span className='stake-info-value partyAmount'>{price && (Number(price) / values.amount).toLocaleString()} PARTY</span></li>
                            </ol>
                            </div>
                             <button className='Purchase-Button' type="button" style={{background: 'grey', cursor: 'not-allowed'}} >
                            Not Connected
                        </button>
                        </div>
                        }

                    </div >
                );
            }}
        </Formik>
          </>
    )
}

export default BuyCard