import React, { useEffect, useState } from 'react'
import SupplyProgress from '../progress/SupplyProgress';
import './Card.css';
import questionMark from "../../assets/images/question.png";
import $ from "jquery";

function SupplyCard(props) {
  const { poolColor, poolData } = props;
  const [currentPrice, setCurrentPrice] = useState([]);

  const showTooltip = () => {
    document.getElementById('toolTipSupply').style.visibility = "visible";
  }

  const hideTooltip = () => {
    document.getElementById('toolTipSupply').style.visibility = "hidden";
  }


useEffect(() => {
  async function getSupplyInfo(){
   let fetchInfo = $.ajax({
          type: 'GET',
          url:'https://api.coingecko.com/api/v3/coins/ethereum/contract/0x0fe0ed7f146cb12e4b9759aff4fa8d34571802ca',
          dataType: "json",
           cache: false,
           success: async function(response)
        {
         setCurrentPrice(await response.market_data.current_price.usd)
        },
        error: async function(xhr, ajaxOptions, thrownError){
          
        }
      });};
   getSupplyInfo();
   const interval = setInterval(() => getSupplyInfo(), 15000)
   return () => {
    clearInterval(interval);
   }

 }, []);



  return (
    <>
    <div className="Card supplyCard" style={{ '--border-color': poolColor }}>
        <div className='Card-title'>
            <h1 className='Card-title-text'>PARTY Supply</h1><img className="questionIcon" id="supplyIcon" onMouseEnter={showTooltip} onMouseLeave={hideTooltip} width="25" src={questionMark} />
            <div className="toolTip" id="toolTipSupply"><h3>PARTY Supply</h3><p>Stats for Burned, Staked and LP Tokens plus Future Incentives.</p></div>
        </div>
        <>

            {/* Pool Favor Info */}
            <div className='supply-info'>
              <div className='supply-chart-container'>
                <SupplyProgress poolData={poolData} />
              </div>
              <div className='supply-info-container'>
              <div className='supply-category'>
              <p className='supplyText supply-info-text'>Total Supply</p>
                <h3 className='supply-info-label'>{poolData && parseInt(parseInt(1000000000) - parseInt(poolData.partyInBurn)).toFixed(2)} PARTY</h3>
                <p id="burnPriceTotal" className='supply-info-text supply-sub-text'>{poolData && (parseInt(parseInt(1000000000) - parseInt(poolData.partyInBurn)) * currentPrice).toFixed(2)} USD</p>
                </div>
                  <div className='supply-category'>
                 <li className='stakedText supply-info-text'>Staked</li>
                <h3 className='supply-info-label'>{poolData && parseInt(parseInt(poolData.pool1Circulating) + parseInt(poolData.pool2Circulating) + parseInt(poolData.pool3Circulating)).toFixed(2)} PARTY</h3>
                <p className='supply-info-text supply-sub-text'>{poolData && parseInt((parseInt(poolData.pool1Circulating) + parseInt(poolData.pool2Circulating) + parseInt(poolData.pool3Circulating)) * currentPrice).toFixed(2)} USD</p>
                </div>
                  <div className='supply-category'>
                 <li className='burnedText supply-info-text'>Burned</li>
                <h3 className='supply-info-label'>{poolData && poolData.partyInBurn} PARTY</h3>
                <p className='supply-info-text supply-sub-text'>{poolData && parseInt(poolData.partyInBurn * currentPrice).toFixed(2)} USD</p>
                </div>
                  <div className='supply-category'>
                 <li className='liquidText supply-info-text'>Liquid</li>
                <h3 className='supply-info-label'>{poolData && poolData.partyInLP} PARTY</h3>
                <p className='supply-info-text supply-sub-text'>{poolData && parseInt(poolData.partyInLP * currentPrice).toFixed(2)} USD</p>
                </div>
                  <div className='supply-category'>
                 <li className='futureText supply-info-text'>Future Incentives</li>
                <h3 className='supply-info-label'>{poolData && parseInt(poolData.partyInFuture).toFixed(2)} PARTY</h3>
                <p className='supply-info-text supply-sub-text'>{poolData && parseInt(poolData.partyInFuture * currentPrice).toFixed(2)} USD</p>
                </div>
              </div>
            </div>
        </>
    </div>
    </>
  );
}

export default SupplyCard;
