import WinningPoolProgress from '../progress/WinningPoolProgress';
import './Card.css';
import questionMark from "../../assets/images/question.png";


function FavorCard(props) {
  const { poolColor, poolData } = props;

  const showTooltip = () => {
    document.getElementById('toolTipFavorMeter').style.visibility = "visible";
  }

  const hideTooltip = () => {
    document.getElementById('toolTipFavorMeter').style.visibility = "hidden";
  }

  return (
    <>
    <div className="Card favorMeterCard" style={{ '--border-color': poolColor }}>
        <div className='Card-title'>
            <h1 className='Card-title-text'>FAVOR Meter</h1><img className="questionIcon" id="favorIcon" onMouseEnter={showTooltip} onMouseLeave={hideTooltip} width="25" src={questionMark} />
             <div className="toolTip" id="toolTipFavorMeter"><h3>Favor Meter</h3><p>The maximum bonus that Favor provides is 25%, which is only achieved when the ratio of one Pool's Favor to the other is infinitely high.</p></div>
        </div>
        
            {/* Pool Favor ring  */}
            <div className='favor-meter'>
                <div className='favor-meter-ring'>
                    <WinningPoolProgress poolData={poolData} />
                </div>
            </div>

            {/* Pool Favor Info */}
            <div className='favor-info'>
              <div className='favor-info-container'>
              <ol className="favor-list">
                <li><span className='favor-stake-info-label'>Pool 1 FAVOR</span><span className='user-stake-info-text favorAmount'>{poolData && poolData.pool1Favor} FAVOR</span></li>
                <li><span className='favor-stake-info-label'>Pool 2 FAVOR</span><span className='user-stake-info-text favorAmount'>{poolData && poolData.pool2Favor} FAVOR</span></li>
              </ol>
              </div>
            </div>
    </div>
    </>
  );
}

export default FavorCard;
