import React, { useState, useEffect } from "react";

function SupplyProgress({ poolData }) {
    const strokeWidth = 90;
    const progressRadius = 180 - strokeWidth / 2;
    const progressCircumference = 2 * Math.PI * progressRadius;
    const maxSupply = 1000000000;

    let liquid = poolData ? parseInt(poolData.partyInLP) : 0;
    let staked = poolData ? parseInt(parseInt(poolData.pool1Circulating) + parseInt(poolData.pool2Circulating) + parseInt(poolData.pool3Circulating)) : 0;
    let future = poolData ? parseInt(poolData.partyInFuture) : 0;
    let burned = poolData ? parseInt(poolData.partyInBurn) : 0;

    let total = staked + liquid + future + burned;
    let stakedPercentage = (staked / total) * 100;
    let liquidPercentage = (liquid / total) * 100;
    let burnedPercentage = (burned / total) * 100;
    let futurePercentage = (future / total) * 100;


    let stakedStrokeDashoffset = progressCircumference - (progressCircumference * stakedPercentage) / 100;
    let liquidStrokeDashoffset = progressCircumference - (progressCircumference * liquidPercentage) / 100;
    let burnedStrokeDashoffset = progressCircumference - (progressCircumference * burnedPercentage) / 100;
    let futureStrokeDashoffset = progressCircumference - (progressCircumference * futurePercentage) / 100;
  
    let liquidAngle = (liquid / total) * 360;
    let liquidRotate = "rotate("+liquidAngle+", 200, 200)";
    let futureAngle = (future / total) * 360;
    let futureRotate = "rotate("+futureAngle+", 200, 200)";
    let stakedAngle = (staked / total) * 360;
    let stakedRotate = "rotate("+stakedAngle+", 200, 200)";
    let triAngle = liquidAngle + stakedAngle;
    let triRotate = "rotate("+triAngle+", 200, 200)";
    let burnedAngle = stakedAngle + futureAngle + liquidAngle;
    let burnedRotate = "rotate("+burnedAngle+", 200, 200)";

    return (
        <div className="supply-container">
            <svg className="supply-ring" viewBox="0 0 400 400">
                {/* Half circle */}
                <defs>
                <linearGradient id="yellowLinear" x1="0%" y1="0%" x2="0%" y2="100%" gradientTransform="rotate(0)">
                <stop offset="0%" stop-color="#F1B859"/>
                <stop offset="100%" stop-color="#F4B859"/>
                </linearGradient>
                <linearGradient id="blueLinear" x1="0%" y1="0%" x2="0%" y2="100%" gradientTransform="rotate(0)">
                <stop offset="0%" stop-color="#5A9AF6"/>
                <stop offset="100%" stop-color="#5D9AF6"/>
                </linearGradient>
                <linearGradient id="redLinear" x1="0%" y1="0%" x2="0%" y2="100%" gradientTransform="rotate(0)">
                <stop offset="0%" stop-color="#EA6667"/>
                <stop offset="100%" stop-color="#EE6667"/>
                </linearGradient>
                <linearGradient id="greenLinear" x1="0%" y1="0%" x2="0%" y2="100%" gradientTransform="rotate(0)">
                <stop offset="0%" stop-color="#51F484"/>
                <stop offset="100%" stop-color="#59F484"/>
                </linearGradient>
                </defs>
                {/* Progress circle */}
                {total === 0 ? (
                     <circle
                    className="supply-ring__circle"
                    strokeWidth={strokeWidth}
                    stroke="#FFF"
                    fill="transparent"
                    r={progressRadius}
                    cx="200"
                    cy="200"
                    transform="rotate(0, 200, 200)"
                /> ) : (
                poolData ? <>
                 <circle
                    className="supply-ring__circle"
                    strokeWidth={strokeWidth}
                    stroke="url(#blueLinear)"
                    fill="transparent"
                    r={progressRadius}
                    cx="200"
                    cy="200"
                    strokeDasharray={progressCircumference}
                    strokeDashoffset={liquidStrokeDashoffset}
                    transform="rotate(0,200,200)"
                />
                <circle
                    className="supply-ring__circle"
                    strokeWidth={strokeWidth}
                    stroke="url(#greenLinear)"
                    fill="transparent"
                    r={progressRadius}
                    cx="200"
                    cy="200"
                    strokeDasharray={progressCircumference}
                    strokeDashoffset={stakedStrokeDashoffset}
                    transform={liquidRotate}
                />
                <circle
                    className="supply-ring__circle"
                    strokeWidth={strokeWidth}
                    stroke="url(#redLinear)"
                    fill="transparent"
                    r={progressRadius}
                    cx="200"
                    cy="200"
                    strokeDasharray={progressCircumference}
                    strokeDashoffset={futureStrokeDashoffset}
                    transform={triRotate}
                />
                <circle
                    className="supply-ring__circle"
                    strokeWidth={strokeWidth}
                    stroke="url(#yellowLinear)"
                    fill="transparent"
                    r={progressRadius}
                    cx="200"
                    cy="200"
                    strokeDasharray={progressCircumference}
                    strokeDashoffset={burnedStrokeDashoffset}
                    transform={burnedRotate}
                />
                   </> : <circle
                    className="supply-ring__circle"
                    stroke="#fff"
                    strokeWidth={strokeWidth}
                    fill="transparent"
                    r={progressRadius}
                    cx="200"
                    cy="200"
                    transform="rotate(0, 200, 200)"
                    
                />)}
            </svg>
        </div>
    );
}
export default SupplyProgress;
