import { useEffect, useState } from "react";
import useSound from "use-sound";
import allinclusive from "../../assets/sounds/allinclusive.mp3";
import { AiFillPlayCircle, AiFillPauseCircle } from "react-icons/ai";
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import { IconContext } from "react-icons";
import poolboy from "../../assets/images/poolboy.jpg";
import "./MusicPlayer.css";
import soundIcon from '../../assets/images/sound.png';

export default function Player() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volumeSet, setVolumeSet] = useState(20);
  const [time, setTime] = useState({
    min: "",
    sec: ""
  });
  const [currTime, setCurrTime] = useState({
    min: "",
    sec: ""
  });
  const [musicOpen, setMusicOpen] = useState();

  const [seconds, setSeconds] = useState();
  const [play, { pause, duration, sound }] = useSound(allinclusive, {volume: volumeSet / 100});

  useEffect(() => {
    if (duration) {
      const sec = duration / 1000;
      const min = Math.floor(sec / 60);
      const secRemain = Math.floor(sec % 60);
      setTime({
        min: min,
        sec: secRemain
      });
    }
  }, [isPlaying]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sound) {
        setSeconds(sound.seek([]));
        const min = Math.floor(sound.seek([]) / 60);
        const sec = Math.floor(sound.seek([]) % 60);
        setCurrTime({
          min,
          sec
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [sound]);

  const playingButton = () => {
    if (isPlaying) {
      pause();
      setIsPlaying(false);
    } else {
      play();
      setIsPlaying(true);
    }
  };

  const musicToggle = () => {
    if(musicOpen){
      document.getElementById('musicBox').style.display = "none";
      setMusicOpen(false);
    }else{
      document.getElementById('musicBox').style.display = "initial";
      setMusicOpen(true);
    }
  }

  return (
    <div className="music-container">
     <button onClick={musicToggle} id="musicClose">[</button>
    <div id="musicBox" style={{display: 'none'}}>
    <div className="music-info">
    <img className="musicCover" src={poolboy} />
        <div className="music-text">
        <div className="music-title">
        {!isPlaying ? (
          <button className="playButton" onClick={playingButton}>
            <IconContext.Provider value={{ size: "1.5em", color: "#5D9AF6" }}>
              <AiFillPlayCircle />
            </IconContext.Provider>
          </button>
        ) : (
          <button className="playButton" onClick={playingButton}>
            <IconContext.Provider value={{ size: "1.5em", color: "#5D9AF6" }}>
              <AiFillPauseCircle />
            </IconContext.Provider>
          </button>
        )}
        <h3 className="music-title">All Inclusive</h3>
        </div>
        <p className="music-artist">Pool Boy, Cyril Hahn</p>
        </div>
      </div>
        <input className="music-scrub"
          type="range"
          min="0"
          max={duration / 1000}
          default="0"
          value={seconds}
          className="timeline"
          onChange={(e) => {
            sound.seek([e.target.value]);
          }}
        />
         <div style={{display: 'flex'}}><img src={soundIcon} width="20" height="20" /><input className="music-scrub"
          type="range"
          min="0"
          max="100"
          default="20"
          value={volumeSet}
          className="timeline"
          onChange={(e) => {
            setVolumeSet([e.target.value]);
          }}
        /></div>
        </div>
    </div>
  );
}
