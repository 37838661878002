import React, { useState, useEffect } from "react";
import "./WinningPoolProgress.css";

function WinningPoolProgress({ poolData }) {
    const strokeWidth = 28;
    const progressRadius = 160 - strokeWidth / 2;
    const progressCircumference = 2 * Math.PI * progressRadius;
    const strokeDasharray = "1 10 1 10 1 10";
    const [currentPercentage, setCurrentPercentage] = useState(0);
    const [favorRatioPercentage, setfavorRatioPercentage] = useState(0);
    const targetPercentage = poolData
        ? poolData.pool1Odds > poolData.pool2Odds
            ? 100 - poolData.pool1Odds
            : 100 - poolData.pool2Odds
        : 0;
    const progressLength = ((currentPercentage / 100) / 2) * progressCircumference;

    useEffect(() => {
        let timeout;
        if(poolData) {
            setCurrentPercentage(
                100 * Number(poolData.pool1Favor / (Number(poolData.pool1Favor) + Number(poolData.pool2Favor)))
            )    
            if(Number(poolData.pool2Favor) > Number(poolData.pool1Favor)) {
                setfavorRatioPercentage(100 * ((Number(poolData.pool2Favor) / Number(poolData.pool1Favor)) - 1))
            } else if (Number(poolData.pool1Favor) > Number(poolData.pool2Favor)) {
                setfavorRatioPercentage(100 * ((Number(poolData.pool1Favor) / Number(poolData.pool2Favor)) - 1))
            } else {
                setfavorRatioPercentage(0)
            }

        }
        // if (currentPercentage < targetPercentage) {
        //     timeout = setTimeout(() => {
        //         setCurrentPercentage(currentPercentage + 1);
        //     }, 10);
        // } else if (currentPercentage > targetPercentage) {
        //     setCurrentPercentage(targetPercentage);
        // }
        return () => clearTimeout(timeout);
    }, [currentPercentage, targetPercentage]);


    return (
        <div className="progress-container">
            <svg className="progress-ring" viewBox="0 0 320 160">
                {/* Half circle */}
                <defs>
                <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%" gradientTransform="rotate(-130)">
                <stop offset="0%" stop-color="#e45cf5"/>
                <stop offset="100%" stop-color="#BDO1FF"/>
                </linearGradient>
                <filter id="dropshadow" height="150%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="1"/>
                <feOffset dx="-2" dy="0" result="offsetblur"/> 
                <feComponentTransfer>
                <feFuncA type="linear" slope="0.5"/> 
                </feComponentTransfer>
                <feMerge> 
                <feMergeNode/>
                <feMergeNode in="SourceGraphic"/>
                </feMerge>
                </filter>
                </defs>
                 <circle
                    className="progress-ring__circle"
                    stroke="#BD01FF"
                    strokeOpacity="100%"
                    strokeWidth={strokeWidth - 25}
                    fill="transparent"
                    r={progressRadius - 10}
                    cx="160"
                    cy="160"
                    strokeDasharray={strokeDasharray}
                />
                <circle
                    className="progress-ring__circle"
                    stroke="#fff"
                    strokeWidth={strokeWidth - 1}
                    fill="transparent"
                    r={progressRadius}
                    stroke-linecap = "round"
                    cx="160"
                    cy="160"
                    strokeDasharray={progressCircumference}
                    strokeDashoffset="488"
                    transform="rotate(185.5 160 160)"
                />
                 <circle
                    className="progress-ring__circle"
                    stroke="#BD01FF"
                    strokeOpacity="100%"
                    strokeWidth={strokeWidth - 25}
                    fill="transparent"
                    r={progressRadius - 20}
                    cx="160"
                    cy="160"
                    strokeDasharray={strokeDasharray}
                />
                <circle
                    className="progress-ring__circle"
                    stroke="#01FFF0"
                    strokeOpacity="60%"
                    strokeWidth={strokeWidth - 25}
                    fill="transparent"
                    r={progressRadius - 30}
                    cx="160"
                    cy="160"
                    strokeDasharray={strokeDasharray}
                />
                <circle
                    className="progress-ring__circle"
                    stroke="#01FFD1"
                    strokeOpacity="50%"
                    strokeWidth={strokeWidth - 25}
                    fill="transparent"
                    r={progressRadius - 40}
                    cx="160"
                    cy="160"
                    strokeDasharray={strokeDasharray}
                />
                <circle
                    className="progress-ring__circle"
                    stroke="#01FFC2"
                    strokeWidth={strokeWidth - 25}
                    fill="transparent"
                    r={progressRadius - 50}
                    cx="160"
                    cy="160"
                    strokeDasharray={strokeDasharray}
                />
                <circle
                    className="progress-ring__circle"
                    stroke="#01FFF0"
                    strokeOpacity="40%"
                    strokeWidth={strokeWidth - 25}
                    fill="transparent"
                    r={progressRadius - 60}
                    cx="160"
                    cy="160"
                    strokeDasharray={strokeDasharray}
                />
                <circle
                    className="progress-ring__circle"
                    stroke="#01FFB3"
                    strokeOpacity="30%"
                    strokeWidth={strokeWidth - 25}
                    fill="transparent"
                    r={progressRadius - 70}
                    cx="160"
                    cy="160"
                    strokeDasharray={strokeDasharray}
                />
                <circle
                    className="progress-ring__circle"
                    stroke="#01D1FF"
                    strokeOpacity="20%"
                    strokeWidth={strokeWidth - 25}
                    fill="transparent"
                    r={progressRadius - 80}
                    cx="160"
                    cy="160"
                    strokeDasharray={strokeDasharray}
                />
                <circle
                    className="progress-ring__circle"
                    stroke="#01D1FF"
                    strokeOpacity="10%"
                    strokeWidth={strokeWidth - 25}
                    fill="transparent"
                    r={progressRadius - 90}
                    cx="160"
                    cy="160"
                    strokeDasharray={strokeDasharray}
                />
                {/* Progress circle */}
                {poolData ? <circle
                    className="progress-ring__circle"
                    strokeWidth={strokeWidth}
                    stroke="url(#linear)"
                    stroke-linecap= "round"
                    filter= "url(#dropshadow)"
                    fill="transparent"
                    r={progressRadius}
                    cx="160"
                    cy="160"
                    strokeDasharray={progressCircumference}
                    strokeDashoffset={progressCircumference - progressLength}
                    transform="rotate(-174.5 160 160)"
                />
                    : <circle
                    className="progress-ring__circle"
                    stroke="#fff"
                    strokeWidth={strokeWidth - 1}
                    fill="transparent"
                    r={progressRadius}
                    stroke-linecap = "round"
                    cx="160"
                    cy="160"
                    strokeDasharray={progressCircumference}
                    strokeDashoffset="488"
                    transform="rotate(185.5 160 160)"
                />}
                <text
                    className="progress-text__percentage"
                    x="50%"
                    y="65%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                >
                    {favorRatioPercentage.toFixed(2)}%
                </text>

                <text
                    className="progress-text__progress"
                    x="50%"
                    y="90%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                >
                    {console.log('currentPercentage', currentPercentage)}
                    {currentPercentage === 50 ? "The Pools are Tied!" : currentPercentage < 50 ? "Pool 2 is winning" : "Pool 1 is winning"}
                </text>
            </svg>
            <div className="progress-text__container">
                <p className="progress-text__label">Pool 1</p>
                <p className="progress-text__label">Pool 2</p>
            </div>
        </div>
    );
}
export default WinningPoolProgress;
